/* ==========================================================================
	Global
	========================================================================== */

/* V - 10-12-2019 */

*::selection {
	background-color: #B3D4FB;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {

	small{
		display: block;
		padding-top: 1rem;
	}
}

.page-header-top {
	margin: 50px 0;
	// position: relative;
	// padding-bottom: 3rem;

	//  &:after{
	// 	content : '';
	// 	position: absolute;
	// 	bottom: 0;
	// 	left:0;
	// 	width: 3rem;
	// 	height: 3px;
	// 	background-color: white;
	// }

	>h1,
	>.h1 {
		margin: 0;
	}
}

.btn, a, button {
	outline: 0 !important;
	transition: all 0.3s;
}

.thumbnail, .panel {
	border: none;
	box-shadow: $box-shadow;
}

.container-lg {
	@extend .container-fluid;
	max-width: 1400px;
}

.container-max {
	@extend .container-lg;

	@media(min-width: 1500px) {
		max-width: 85%;
	}
}

.container-main {
	@extend .container-max;
	padding: 0 5% 6%;
}

.no-pad {
	padding: 0;
}

.no-marge {
	margin: 0;
}

/* ==========================================================================
	Headers
	========================================================================== */

@import "header-basic";
// @import "header-basic-2";
// @import "header-slider-1";
// @import "header-slider-2";
// @import "header-slider-3";
// @import "header-slider-4";
// @import "header-slider-5";

/* ==========================================================================
	Navbar
	========================================================================== */

// .navbar-centered {

// 	@media (min-width: $grid-float-breakpoint) {
// 		.navbar-nav {
// 			display: inline-block;
// 			float: none;
// 			vertical-align: top;
// 		}

// 		.navbar-collapse {
// 			text-align: center;
// 		}
// 	}
// }

// .navbar {
	// @extend .navbar-centered; // pour centrer le contenu de la navbar.
// }

.navbar-nav {
	@extend .navbar-right; // pour aligner � droite.

	>li {

		>a {
			position: relative;

			&::after {
				content: '';
				position: absolute;
				bottom: 0;
				left: 50%;
				right: 50%;
				height: 0;
				background-color: $brand-primary;
				opacity: 0;
				transition: all ease-in-out .3s;
			}
		}

		&:hover>a::after,
		&:active>a::after,
		&:focus>a::after,
		&.active>a::after {
			left: 1rem;
			right: 1rem;
			height: 3px;
			opacity: 1;
		}
	}
}

.index-nav, .index-red {
	@extend .pull-right;
	display: inline-block;
	line-height: $navbar-height;
	padding-right: 10px;
	color: $navbar-default-toggle-icon-bar-bg;
	text-transform: uppercase;
	font-weight: 700;

	&--text {
		color: red;
	}

	@media (min-width: $grid-float-breakpoint) {
		display: none;
	}
}

/* ==========================================================================
	Main
	========================================================================== */

.main {
	@extend .clearfix;
	min-height: 50vh;
}

/* ==========================================================================
	Col Main
	========================================================================== */

.col-main {
	@extend .col-md-9;
	@extend .col-md-push-3;
	padding-bottom: 6rem;
}

/* ==========================================================================
	Col Aside
	========================================================================== */

.col-aside {
	@extend .col-md-3;
	@extend .col-md-pull-9;
	padding-top: 3rem;
}

/* ==========================================================================
	Footer
	========================================================================== */

.footer {
	background-color: $gray-dark;
	color: white;
}

.container-footer {
	@extend .container-max;
	@extend .text-center;
	padding: 5%;

	@media (min-width: $screen-sm-min) {
		text-align: left;
	}
}

.footer-main {
	@extend .clearfix;
}

.footer-address {
	line-height: 1.8;
}

ul.list-footer {
	@extend .list-unstyled;
	margin: 0;

	>li {
		padding: 5px 0;
	}

	@media (max-width: $screen-xs-max) {
		padding-top: 20px;
		margin-top: 20px;
		border-top: solid 1px rgba(black, .2);
	}
}

ul.list-footer-right {
	@extend .list-unstyled;
	margin: 0;

	>li {
		padding: 5px 0;
	}
}

ul.list-logo-footer {
	@extend .list-inline;
	margin-top: 30px;
	padding-top: 30px;
	border-top: solid 1px $hr-border;

	>li {
		padding: 0;
	}

	@media (max-width: $screen-sm-max) {
		text-align: center;
		padding-top: 20px;
		margin-top: 20px;
		border-top: solid 1px r$hr-border;
	}

	img {
		height: 37px;
	}
}

.panel-ref-footer {
	@extend .well;
}

/* ==========================================================================
	Custom
	========================================================================== */

.content-slider {
	margin: 30px auto;
	max-width: 1000px;
	box-shadow: $box-shadow;
}

.section-actu {
	@extend .container-max;

	@media(min-width: $screen-md-min) {
		padding: 0 30px;
	}
}

//********** MIXINS

@mixin breakpoint($point) {
  @if $point == lg {
    @media only screen and (min-width: $screen-lg-min) {
      @content;
    }
  } @else if $point == md {
    @media only screen and (min-width: $screen-md-min) {
      @content;
    }
  } @else if $point == sm {
    @media only screen and (min-width: $screen-sm-min) {
      @content;
    }
  } @else if $point == xs {
    @media only screen and (min-width: $screen-xs-min) {
      @content;
    }
  }
}

@mixin background(
  $imgpath: "",
  $overlay: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0))
) {
  background: $overlay, url($imgpath) center center no-repeat;
  background-size: cover, cover;
}

@mixin background2($imgpath: "", $color: rgba(0, 0, 0, 0), $opacity: 0) {
  background: linear-gradient(rgba($color, $opacity), rgba($color, $opacity)),
    url($imgpath) center center no-repeat;
  background-size: cover, cover;
}

//********** FLEXI BOOTSTRAP FROM Brian Willis

@media only screen and (min-width: $screen-md-min) {
  .flex-row.row {
    display: flex;
    flex-wrap: wrap;
  }
  .flex-row.row > [class*="col-"] {
    display: flex;
    flex-direction: column;
  }
  .flex-row.row:after,
  .flex-row.row:before {
    display: flex;
  }
}

//********* COMPONENTS
body {
  overflow-x: hidden;
}
.contact-btn {
  position: absolute;
  overflow: hidden;
  top: 3rem;
  right: -5.4rem;
  background-color: red;
  padding: 1rem 1.6rem;
  transition: right 0.3s;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.5);
}
.contact-btn:hover {
  background-color: darken(red, 10%);
  right: 0;
}

.contact-btn__link {
  color: #ffffff;
  font-size: 1rem;
  font-weight: 700;
}

.contact-btn__link:before {
  display: inline-block;
  overflow: visible;
  vertical-align: -0.1rem;
  width: 1rem;
  content: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false'  data-icon='envelope' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' %3E%3Cpath fill='currentColor' d='M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z' class=''%3E%3C/path%3E%3C/svg%3E");
  filter: invert(100%) sepia(0%) saturate(7449%) hue-rotate(76deg)
    brightness(96%) contrast(110%);
  margin-right: 1.1rem;
}
.contact-btn__link:hover {
  text-decoration: none;
  color: white;
}

.btn-outline-primary {
  border: 1px solid $brand-primary;
  border-color: $brand-primary;
  background-color: transparent;
  &:hover {
    color: white;
    background-color: $brand-primary;
    border-color: $brand-primary;
    text-decoration: none;
  }
  &:focus {
    text-decoration: none;
  }
}

.btn-outline-success {
  border: 1px solid $brand-success;
  border-color: $brand-success;
  background-color: transparent;
  &:hover {
    color: white;
    background-color: $brand-success;
    border-color: $brand-success;
    text-decoration: none;
  }
  &:focus {
    text-decoration: none;
  }
}

.card {
  @include padding-top(1.25em);
  @include padding-bottom(1.25rem);
  @include padding-left(1.25rem);
  @include padding-right(1.25rem);
  border-radius: $border-radius-base;
  height: 100%;
}

.card-border {
  @include padding-top(1.25em);
  @include padding-bottom(1.25rem);
  @include padding-left(1.25rem);
  @include padding-right(1.25rem);
  border-radius: $border-radius-base;
  border: 1px solid #ccc;
  height: 100%;
}

.header-basic {
  // position: relative;
  margin-top: 35px; // If e-commercer bar
  border-bottom: solid 1px #e8e8e8;
  .content-header {
    // padding-top: 2rem;
  }
  .logo-header {
    > img {
      // @extend .margin-responsive;
      //@extend .img-responsive;
      max-width: 100%;
      margin-top: 10px;
      margin-bottom: 10px;
      @media (max-width: $screen-xs-min) {
        display: block;
        margin: auto;
      }
      @media (max-width: $screen-sm-min) {
        display: block;
        margin: auto;
      }
    }
  }
}

/* to add a btn style in nav */

@include breakpoint(sm) {
  .navbar-nav > li:last-child {
    padding: 0.4rem 0;

    a {
      border: 2px solid $brand-primary;
      border-radius: $btn-border-radius-base;
      padding: 0.5rem 1.5rem;
      color: $brand-primary;

      &:hover {
        border: 2px solid darken($brand-primary, 10);
        background: darken($brand-primary, 10);
        color: white;
      }

      &::after {
        display: none;
      }
    }
  }
}

//********* FONTS

.hero__t {
  @include font-size(3.7rem);
  line-height: 1.1;
  font-weight: 800;
  @include margin-bottom(1rem);
  margin-top: 0;
	font-family: $font-family-sans-serif2;
}

.hero__st {
  @include font-size(1.618rem);
  @include margin-bottom(1rem);
}

.t {
  margin-top: 0;
  font-weight: 800;
  @include margin-bottom(1rem);
  @include font-size(3rem);
	font-family: $font-family-sans-serif2;
}
.t2 {
  margin-top: 0;
  font-weight: 800;
  @include margin-bottom(1rem);
  @include font-size(2.4rem);
	font-family: $font-family-sans-serif2;
}

.st {
  font-weight: 700;
  @include font-size(1.418rem);
}
.st2 {
  font-weight: 700;
  @include font-size(1.2rem);
}
.p {
  @include font-size(1.1rem);
}
.p2 {
  @include font-size(1rem);
}

.list {
  list-style: none;
  @include font-size(1rem);
  padding-left: 0.1rem;
  &__item {
    line-height: 1.8;
    position: relative;
    &:before {
      content: "+";
      font-weight: 700;
      color: $brand-primary;
      margin-right: 0.8rem;
      line-height: 1.8;
      @include font-size(1rem);
    }
  }
}

.list-line {
  position: relative;
  padding-left: 0.2rem;
  &::before {
    content: "";
    position: absolute;
    top: 0px;
    bottom: 0px;
    border: 1px dashed $brand-primary;
  }
  &__item {
    display: block;
    position: relative;
    left: -20px;
    background-color: #eee;
    padding: 5px;
    padding-left: 20px;
    margin: 40px 0px;
  }
}

ul.listIcon {
  list-style: none;
  padding: 0;
}
ul.listIcon > li {
  padding-left: 1.3em;
	padding-bottom: 1rem;
}
ul.listIcon > li:before {
  content: "\f058"; /* FontAwesome Unicode */
  font-family: FontAwesome;
  display: inline-block;
  margin-left: -1.3em; /* same as padding-left set on li */
  width: 1.3em; /* same as padding-left set on li */
	color: $brand-primary;
}

.blockquote {
  position: relative;
  border-left: transparent;
  font-style: italic;
  @include font-size(1.2rem);
  &::before {
    content: "\201C"; /*Unicode for Left Double Quote*/

    /*Font*/
    font-family: Georgia, serif;
    @include font-size(3rem);
    font-weight: bold;
    color: #999;

    /*Positioning*/
    position: absolute;
    left: -5%;
    top: -5%;
  }

  &::after {
    /*Reset to make sure*/
    content: "";
  }
}

span.underline {
  background-image: linear-gradient(
    90deg,
    lighten($brand-primary, 20%),
    lighten($brand-primary, 20%)
  );
  background-repeat: no-repeat;
  background-size: 100% 1rem;
  background-position: 0 115%;
}

.lineafter-center {
  &:after {
    content: "";
    display: block;
    height: 0.5rem;
    width: 25%;
    margin: 2rem auto;
    border-radius: 35px;
    background: $brand-primary;
  }
}

.lineafter-center__white {
  &:after {
    content: "";
    display: block;
    height: 0.5rem;
    width: 25%;
    margin: 2rem auto;
    border-radius: 35px;
    background: white;
  }
}

.lineafter {
  &:after {
    content: "";
    display: block;
    height: 0.5rem;
    width: 25%;
    margin: 2rem auto;
    border-radius: 35px;
    background: $brand-primary;
  }
}

.lineafter__white {
  &:after {
    content: "";
    display: block;
    height: 0.5rem;
    width: 25%;
    margin: 2rem 0;
    border-radius: 35px;
    background: white;
  }
}

.big-t {
  font-size: 5rem;
  color: #f2f1f2;
  font-weight: 800;
  font-family: sans-serif;
}

//********** DISPLAY

@include breakpoint(sm) {
  .d-flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.d-flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.align-center {
  align-items: center;
}

.flex-wrap {
  flex-wrap: wrap;
}

.basis-card {
  flex-basis: 18rem;
}

.relative {
  position: relative;
}

//********** SPACINGS

.s {
  @include padding-top(10rem);
  @include padding-bottom(10rem);
}

.s2 {
  @include padding-top(3rem);
  @include padding-bottom(3rem);
}

.hero {
  @include padding-top(12rem);
  @include padding-bottom(12rem);
}

.mt-1 {
  @include margin-top(1rem);
}

.mt-2 {
  @include margin-top(2rem);
}

.mt-3 {
  @include margin-top(3rem);
}

.mt-4 {
  @include margin-top(4rem);
}

.mt-5 {
  @include margin-top(5rem);
}

.mb-1 {
  @include margin-bottom(1rem);
}

.mb-2 {
  @include margin-bottom(2rem);
}

.mb-3 {
  @include margin-bottom(3rem);
}

.mb-4 {
  @include margin-bottom(4rem);
}

.mb-5 {
  @include margin-bottom(5rem);
}

.my-1 {
  @include margin-top(1rem);
  @include margin-bottom(1rem);
}

.my-2 {
  @include margin-top(2rem);
  @include margin-bottom(2rem);
}

.my-3 {
  @include margin-top(3rem);
  @include margin-bottom(3rem);
}

.my-4 {
  @include margin-top(4rem);
  @include margin-bottom(4rem);
}

.my-5 {
  @include margin-top(5rem);
  @include margin-bottom(5rem);
}

.mx-1 {
  @include margin-left(1rem);
  @include margin-right(1rem);
}

.mx-2 {
  @include margin-left(2rem);
  @include margin-right(2rem);
}

.mx-3 {
  @include margin-left(3rem);
  @include margin-right(3rem);
}

.mx-4 {
  @include margin-left(4rem);
  @include margin-right(4rem);
}

.mx-5 {
  @include margin-left(5rem);
  @include margin-right(5rem);
}

.pt-1 {
  @include padding-top(1rem);
}

.pt-2 {
  @include padding-top(2rem);
}

.pt-3 {
  @include padding-top(3rem);
}

.pt-4 {
  @include padding-top(4rem);
}

.pt-5 {
  @include padding-top(5rem);
}

.pb-1 {
  @include padding-bottom(1rem);
}

.pb-2 {
  @include padding-bottom(2rem);
}

.pb-3 {
  @include padding-bottom(3rem);
}

.pb-4 {
  @include padding-bottom(4rem);
}

.pb-5 {
  @include padding-bottom(5rem);
}

.py-1 {
  @include padding-top(1rem);
  @include padding-bottom(1rem);
}

.py-2 {
  @include padding-top(2rem);
  @include padding-bottom(2rem);
}

.py-3 {
  @include padding-top(3rem);
  @include padding-bottom(3rem);
}

.py-4 {
  @include padding-top(4rem);
  @include padding-bottom(4rem);
}

.py-5 {
  @include padding-top(5rem);
  @include padding-bottom(5rem);
}

.px-1 {
  @include padding-left(1rem);
  @include padding-right(1rem);
}

.px-2 {
  @include padding-left(2rem);
  @include padding-right(2rem);
}

.px-3 {
  @include padding-left(3rem);
  @include padding-right(3rem);
}

.px-4 {
  @include padding-left(4rem);
  @include padding-right(4rem);
}

.px-5 {
  @include padding-left(5rem);
  @include padding-right(5rem);
}

.mr-1 {
  @include margin-right(1rem);
}

.mr-2 {
  @include margin-right(2rem);
}

.mr-3 {
  @include margin-right(3rem);
}

hr.hr-white {
  border-top: 1px solid white;
}

hr.hr-light {
  border-top: 1px solid $gray-light;
}

hr.hr-gray {
  border-top: 1px solid $gray;
}

hr.hr-graydark {
  border-top: 1px solid $gray-dark;
}

//********* TEXT

.text-wh {
  color: white;
}

.text-dark {
  color: #333;
}

.strong {
  font-weight: 600;
}

//********* EFFECTS

.shadow {
  box-shadow: 1rem 2rem 4rem -2rem rgba(black, 0.9);
}

//********* BG

.bg-1 {
  @include background2("https://picsum.photos/id/1018/1920/1280", #000, 0.3);
}
.bg-1hero {
  @include background2("../images/assets/bg-1hero.jpg", #000, 0.0);
}
.bg-1b {
  @include background2("../images/assets/1b.jpg", #000, 0.0);
}
.bg-1c {
  @include background2("../images/assets/1c.jpg", #000, 0.0);
}
.bg-1d {
  @include background2("../images/assets/1d.jpg", #000, 0.0);
}


.bg-2 {
  @include background2("https://picsum.photos/id/1018/1920/1280");
}

.bg-3 {
  @include background2("https://picsum.photos/id/1018/1920/1280");
}

.bg-gradient {
  @include background("", linear-gradient(45deg, #e66465, #9198e5));
}

.bg-white {
  background-color: white;
}

.bg-softgray {
  background-color: #eee;
}

.bg-softgray-g {
  @include background("", linear-gradient(180deg, #e7e7e7, #ffffff));
}

.bg-darkgray {
  background-color: #111;
}

.bg-danger {
  background-color: $brand-danger !important;
  color: white;
}

.bg-primary-2 {
  background-color: lighten($brand-primary, 5%);
}

.bg-primary-3 {
  @include background2("", $brand-primary, 0.7);
}

.bg-success {
  background-color: $brand-success;
}

.bg-marielouise {
  @include background2("https://picsum.photos/id/1018/1920/1280", #fff, 0);
  border: 5vw solid white;
}

.after-1 {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 25%;
}

//********* OTHERS

.no-gutter {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0;
}

.embed-5 {
  padding-bottom: 30rem;
}

.h-100 {
  height: 100%;
}

.affix {
  top: 0;
  right: 0;
  left: 0;
}

//********* >>> CUSTOM
//********* >>> CUSTOM

