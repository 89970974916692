/* ==========================================================================
	Navbar e-commerce
	========================================================================== */

.navbar-top {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	z-index: 3000;
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-end;
	background-color: $brand-info;
	padding: 0 10px;
	font-size: .8rem;

	>a,
	>button {
		border:none;
		display: inline-block;
		background-color: transparent;
		padding: 0 10px;
		height: 35px;
		line-height: 35px;
		border-left: solid 1px $gray-lighter;
		color: $gray-darker;

		&:hover {
			color: $brand-primary;
		}

		>i {
			margin-right: .5rem;
		}
	}
}

.dropdown-panel {
	position: absolute;
	z-index: 3000;
	top: 35px;
	right: 0;
	width: 500px;
	max-width: 100%;
	padding: 30px;
	background-color: white;
	box-shadow: 0 10px 30px rgba(black, .1);
}

.dropdown-search {
	@extend .dropdown-panel;
}

.dropdown-cart {
	@extend .dropdown-panel;
}

.dropdown-account {
	@extend .dropdown-panel;
}

.btn-close {
	@extend .btn;
	@extend .btn-danger;
	@extend .btn-sm;
}

.btn-caddie {
	@extend .btn;
	@extend .btn-success;
	@extend .btn-sm;
}
